'use client'

import 'minireset.css/minireset.min.css'
import './global.css'
import 'swiper/css'
import 'swiper/css/pagination'
import './fonts.css'

import App, { AppContext, AppInitialProps, AppProps } from 'next/app'
import { Provider } from 'react-redux'
import store from '~/store/redux-store'
import dynamic from 'next/dynamic'
import getConfig from 'next/config'

import { AuthGuard } from '~/components/layout/content/wrappers/AuthGuard'
import { ProcessListener } from '~/components/layout/content/wrappers/ProcessListener'
import { HeadWrapper } from '~/components/layout/content/wrappers/HeadWrapper'
import { YandexMetrikaProvider } from '~/components/layout/content/wrappers/yandexMetrika/YandexMetrikaProvider'
import { WhitelabelConfig } from '~/models'
import { whitelabelActions } from '~/store/slices/whitelabel'
import { StyledComponentsWrapper } from '~/components/layout/content/wrappers/StyledComponentsWrapper'
import { BodyScrollLockWrapper } from '~/components/layout/content/wrappers/BodyScrollLockWrapper'
import { ErrorBoundary } from '@sentry/nextjs'
import { FallbackComponent } from '@/FallbackComponent'
import { Services } from '~/components/layout/content/wrappers/Services'
import AkbarsOffline from '~/components/akbars-offline'

require('abortcontroller-polyfill/dist/polyfill-patch-fetch')
require('intersection-observer')

interface AppConfigProps {
  config?: WhitelabelConfig
}

const { publicRuntimeConfig } = getConfig()
const IntlWrapper = dynamic(() => import('~/components/layout/content/wrappers/IntlWrapper'), {
  ssr: false,
})

function _App({ Component, pageProps, config }: AppProps & AppConfigProps) {
  // config will be defined only when rendering on the server-side
  if (config) {
    store.dispatch(whitelabelActions.setWhitelabelConfig(config))
  }

  const content = (
    <Provider store={store}>
      <YandexMetrikaProvider>
        <Services>
          <BodyScrollLockWrapper>
            <IntlWrapper>
              <HeadWrapper>
                <StyledComponentsWrapper>
                  <AuthGuard pageProps={pageProps} Component={Component} />
                  <ProcessListener />
                </StyledComponentsWrapper>
              </HeadWrapper>
            </IntlWrapper>
          </BodyScrollLockWrapper>
        </Services>
      </YandexMetrikaProvider>
    </Provider>
  )

  if (config?.company === 'akbars') {
    return <AkbarsOffline />
  }

  return process.env.NODE_ENV !== 'production' ? (
    content
  ) : (
    <ErrorBoundary fallback={FallbackComponent}>{content}</ErrorBoundary>
  )
}

_App.getInitialProps = async (appContext: AppContext): Promise<AppInitialProps & AppConfigProps> => {
  const initialProps = await App.getInitialProps(appContext)

  let config: WhitelabelConfig | undefined

  if (appContext.ctx.req) {
    // host may be either example.com or example.com:80, we need only part before port
    const hostname = appContext.ctx.req.headers['host']!.split(':')[0]

    config = publicRuntimeConfig.getWhitelabelConfig(hostname) as WhitelabelConfig
  }

  return {
    ...initialProps,
    config,
  }
}

export default _App
